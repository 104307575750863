import ApiService from "@/core/services/api.service";
import {EXPORT_FILE_DEFAULT_HOA_DON} from "@/core/services/store/contract/studentAmount.module";

const _ = require('lodash');
export const GET_LIST_TEST = "get_list_test";
export const GET_STUDENT_BY_CLASS = "get_student_by_class";
export const UPDATE_TESTER_ID = "update_tester_id";
export const APPROVE_DO_TEST = "approve_do_test";
export const GET_LIST_TEST_DETAIL = "get_list_test_detail";
export const GET_DETAIL_MARK = "get_detail_mark";
export const SUBMIT_MARK = "submit_mark";
export const REDOING_TEST = "redoing_test";
export const EXPORT_FILE_DEFAULT_TEST_SCORE = "exam/export-file-default-test-score";
export const GET_DATA_IMPORT = "exam/get-data-import";
export const IMPORT_EXAM = "exam/import-exam";
export const LIST_TYPE_TEST_EXAM = "exam/get-type-list-test";
export const GET_LIST_TEST_IN_CLASS = "exam/get-list-test-in-class";
export const POST_SCORE_EXAM = "exam/post-score-exam";
export const GET_HISTORY_TEST = "exam/history-test";
export const GET_ORDER = "exam/get-order";

const state = {};
const getters = {};
const actions = {
    /**
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [GET_LIST_TEST](context, payload) {
        return new Promise(resolve => {
            ApiService.query('exam/list-test', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [GET_DETAIL_MARK](context, payload) {
        return new Promise(resolve => {
            ApiService.query('exam/list-mark-schedule-exam', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [GET_LIST_TEST_DETAIL](context, payload) {
        return new Promise(resolve => {
            ApiService.query('exam/list-test-detail', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    /**
     *
     * @param context
     * @param params
     * @returns {Promise<unknown>}
     */
    [GET_STUDENT_BY_CLASS](context, params) {
        return new Promise(resolve => {
            ApiService.query('exam/student-in-class', params).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    /**
     *
     * @param context
     * @param params
     * @returns {Promise<unknown>}
     */
    [UPDATE_TESTER_ID](context, params) {
        return new Promise(resolve => {
            ApiService.post('exam/update-tester', params).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    /**
     *
     * @param context
     * @param params
     * @returns {Promise<unknown>}
     */
    [REDOING_TEST](context, params) {
        return new Promise(resolve => {
            ApiService.post('exam/redo-test', params).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    /**
     *
     * @param context
     * @param params
     * @returns {Promise<unknown>}
     */
    [APPROVE_DO_TEST](context, params) {
        return new Promise(resolve => {
            ApiService.post('exam/approve-do-test', params).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [SUBMIT_MARK](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post('exam/mark-schedule-exam', params).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response);
            });
        })
    },
    [IMPORT_EXAM](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(IMPORT_EXAM, params).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response);
            });
        })
    },
    [EXPORT_FILE_DEFAULT_TEST_SCORE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(EXPORT_FILE_DEFAULT_TEST_SCORE, payload).then(({ data }) => {
                let filename = "File-mau-import-diem-thi-buoi-kiem-tra.xlsx";
                let anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch(({ response }) => {
                reject(response);
            });
        });
    },
    [GET_DATA_IMPORT](context, params) {
        return new Promise(resolve => {
            ApiService.post(GET_DATA_IMPORT, params).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [LIST_TYPE_TEST_EXAM](context, payload) {
        return new Promise(resolve => {
            ApiService.query(LIST_TYPE_TEST_EXAM, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_LIST_TEST_IN_CLASS](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_LIST_TEST_IN_CLASS, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [POST_SCORE_EXAM](context, params) {
        return new Promise(resolve => {
            ApiService.post(POST_SCORE_EXAM, params).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_HISTORY_TEST](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_HISTORY_TEST, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_ORDER](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_ORDER, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
